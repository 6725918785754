import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpService } from '../shared/services/http.service';
import { environment } from '../../environments/environment';
import { CryptoSharkService } from '../shared/services/crypto-shark.service';
import { UserService } from '../shared/services/user.service';
import { SocketService } from '../shared/services/socket.service';
import { LanguageService } from '../shared/services/language.service';
import { ILogin } from '../interfaces/ilogin';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-authentication',
  templateUrl: './authentication.component.html',
  styleUrls: ['./authentication.component.scss'],
})
export class AuthenticationComponent implements OnInit {
  
  loginMFA = false;

  @ViewChild('modalCredentials') private modalCredentials: TemplateRef<any>;
  
  public loginForm: FormGroup;
  public submitted: boolean = false;
  public loginData: ILogin = {};
  public returnUrl: string
  public expiredSesionAlert: boolean;
  public invalidCredentials: boolean;

  public languages = [];
  public selectedLanguage: any = {};
  public jsonLanguage: any = {};
  public translate_json: any = {};

  public mfa = [null, null, null, null, null, null];

  public get = (url: string, contentType?: string): Observable<any> => this.http.get(`${environment.urlAdmin}${url}`, contentType ? { headers: { "Content-Type": contentType } } : {});
  
  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private httpService: HttpService,
    private cryptoService: CryptoSharkService,
    private userService: UserService,
    private socketService: SocketService,
    private http: HttpClient,
    private modalService: NgbModal,
    private languageService: LanguageService,
  ) { 
  }

  async ngOnInit() {    
    
    this.expiredSesionAlert = this.route.snapshot.queryParams['returnUrl'] ? true : false 
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/analitics'
    this.getSubdomain();
    this.socketService.connect();

    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required],
      nip: ['', Validators.required],
    });
    await this.languageService.getSettingsLanguage();
    this.translate_json = this.languageService?.json_lang?.web?.default?.components?.authentication;
    this.languages = this.languageService?.languages_list;
    this.selectedLanguage = this.languageService?.selected_lang;
  }


  get loginFormControl() {
    return this.loginForm.controls;
  }

  /**
   * Obtener el dominio del cliente
   * @returns Dominio del cliente
   */
  getSubdomain() {
    const domain = window.location.hostname;
    if (
      domain.indexOf('.') < 0 ||
      domain.split('.')[0] === 'example' ||
      domain.split('.')[0] === 'lvh' ||
      domain.split('.')[0] === 'www' ||
      domain.split('.')[0] === '45'
    ) {
      environment.subdomain = 'dani';
    } else {
      environment.subdomain = domain.split('.')[0];
    }
  }

  Submit() {
    this.get(`api/login/company/${environment.subdomain}`).subscribe(async (response) => {
        try {
          let data = await this.httpService.getEva(`api/login/company/${environment.subdomain}`).toPromise();
          this.loginData.checker = data?.data?.server;
        } catch (error) {
          console.log("No se encontro la app checador en el cliente.");
          
        }

        this.loginData.company = response.data;
        environment.apiUrl = this.loginData.company.server;

        if(this.loginForm.invalid){ return;}
 
        this.httpService.post(`api/login`, {
          data: this.loginData.company.data,
          key: this.loginData.company.key,
          user: this.cryptoService.encryptAes(this.loginForm.controls['username'].value,this.loginData.company.key),
          password: this.cryptoService.encryptSha(this.loginForm.controls['password'].value),
          nip: this.cryptoService.encryptSha(this.loginForm.controls['nip'].value),
          subdomain: this.cryptoService.encryptAes(environment.subdomain ,this.loginData.company.key),          
        }).toPromise().then((response: any) => { 


          this.loginData.token = response.token;
          this.loginData.subdomain = environment.subdomain;
          this.socketService.joinRoom(environment.subdomain);
          this.loginData.isRegister = response?.isRegistered || false;
          this.userService.signInCurrentUser(this.loginData);
          if (response?.isRegistered) {
            this.router.navigateByUrl(this.returnUrl);
          }else{
            this.openModal(this.modalCredentials, "md");
          }


        }).catch((e) => {
          console.log(e);
          
          this.invalidCredentials = e.error.message ? true : false;
        })
          
    });
  
  }
  
  checkMFA(event: any){
    let element;

    let code = this.mfa.join("");
    if(code.length == 6 ){
      this.httpService.post(`api/login/mfa/${code}`, {
        data: this.loginData.company.data,
        key: this.loginData.company.key,
        user: this.cryptoService.encryptAes(this.loginForm.controls['username'].value,this.loginData.company.key),
        password: this.cryptoService.encryptSha(this.loginForm.controls['password'].value),
        nip: this.cryptoService.encryptSha(this.loginForm.controls['nip'].value),
      }).toPromise().then((response: any) => { 
        this.loginData.token = response.token;
        this.socketService.joinRoom(environment.subdomain)
        this.userService.signInCurrentUser(this.loginData);
        this.router.navigateByUrl(this.returnUrl)
      }).catch((e) => {
        this.invalidCredentials = e.error.message ? true : false;
      })
    }else{
      if (event.code !== 'Backspace') element = event.srcElement.nextElementSibling;
      if (event.code === 'Backspace') element = event.srcElement.previousElementSibling;
      if(element == null) return; else element.focus();
    }
  }

  private openModal(modal, size: string = 'xxl') {
    this.modalService.open(modal, { size: size, backdrop: 'static' });
  }

  public updateCredentials():void{
    this.loginData.isRegister = true;
    this.userService.signInCurrentUser(this.loginData);
    this.router.navigateByUrl(this.returnUrl);
  }

  public async changeLanguage(language){
    try {
      if (language) {
        await this.languageService.setSettingsLanguage(language,false);
        this.translate_json = this.languageService.json_lang.web.default.components.authentication;
        this.selectedLanguage = this.languageService.selected_lang;
      }
    } catch (error) {
     console.log(error);
    }
  }
}

 